/// <reference path="/dist/scripts/!shared/angular/angular-bundle.js"
/// <reference path="/dist/scripts/!shared/jquery/jquery-bundle.js"

(function (app) {
    'use strict';
    var newsPerPage = 10;
    app.run(['$rootScope', function ($rootScope) {
    }]);

    app.service('helper', [function () {
        return {
        };
    }]);

    app.filter('html', ['$sce', function ($sce) {
        return function (val) {
            return $sce.trustAsHtml(val);
        };
    }]);

    app.service("newsList", ['$location', '$http',
        function ($location, $http) {
            this.lastNewsSent = false;
            var defaultSkip = 0, defaultTake = 10;

            var loadNews = function (skip, take) {
                if (typeof skip === 'undefined') skip = defaultSkip;
                if (typeof take === 'undefined') take = defaultTake;

                return $http.get($location.path() + "?ajax=news&skip=" + skip + "&take=" + take)
                    .then(function (response) {
                        var result = null;
                        var colSizes = [
                            "4 largeCell",
                            "5 normCell",
                            "3 normCell",
                            "3 normCell",
                            "5 normCell",

                            "3 normCell",
                            "5 normCell",
                            "4 pull-right largeCell",
                            "5 normCell",
                            "3 normCell"];

                        console.log(response);
                        console.log(response.data);

                        if (response && response.data)
                            result = angular.fromJson(response.data);

                        if (result && result.news.length) {
                            for (var i = 0; i < result.news.length; i++)
                                result.news[i].colSize = colSizes[i % 10];
                        }

                        console.log(result);

                        return result;
                    });
            }

            var get = function (skip, take) {
                return loadNews(skip, take);
            };

            return {
                get: get
            };
        }]);

    app.controller("PaginationCtrl", ['$scope', '$timeout', '$uibModal', '$log', '$http', 'newsList',
        function ($scope, $timeout, $uibModal, $log, $http, newsList) {
            $scope.items = ['item1', 'item2', 'item3'];
            $scope.animationsEnabled = true;

            var news = newsList;
            $scope.itemsPerPage = newsPerPage;
            $scope.currentPage = 0;
            $scope.lastNewsSent = false;
            $scope.pagedItems = [];

            $scope.open = function ($event, link, size) {
                $event.preventDefault();
                if (!link) {
                    link = $event.delegateTarget.href;
                }

                $http.get(link).then(function (response) {
                    var modalInstance = $uibModal.open({
                        animation: $scope.animationsEnabled,
                        templateUrl: 'newsModal.html',
                        controller: 'NewsModalInstanceCtrl',
                        size: size,
                        resolve: {
                            item: function () {
                                return response.data.item;
                            }
                        }
                    });

                    modalInstance.result.then(function (selectedItem) {
                        $scope.selected = selectedItem;
                    }, function () {
                        $log.info('Modal dismissed at: ' + new Date());
                    });
                });
            }

            var readAnswer = function (response) {
                if (response) {
                    $timeout(function () {
                        $scope.pagedItems = $scope.pagedItems.concat(response.news);
                        $scope.lastNewsSent = response.lastNewsSent;
                        $scope.$apply();
                    });
                }
            }
            // load first page
            news.get($scope.currentPage * $scope.itemsPerPage, $scope.itemsPerPage)
                .then(readAnswer);

            // load other pages
            $scope.loadMore = function () {
                $scope.currentPage++;
                news.get($scope.currentPage * $scope.itemsPerPage, $scope.itemsPerPage)
                    .then(readAnswer);
            };

            $scope.nextPageDisabledClass = function () {
                return $scope.lastNewsSent ? "disabled" : "";
            };
        }]);

    app.controller("FirstNewsCtrl", ['$scope', '$timeout', '$uibModal', '$log', '$http', 'newsList',
        function ($scope, $timeout, $uibModal, $log, $http, newsList) {
            $scope.animationsEnabled = true;

            var news = newsList;
            $scope.itemsPerPage = newsPerPage;
            $scope.firstNews = [];

            $scope.open = function ($event, link, size) {
                $event.preventDefault();
                if (!link) {
                    link = $event.delegateTarget.href;
                }

                $http.get(link).then(function (response) {
                    var modalInstance = $uibModal.open({
                        animation: $scope.animationsEnabled,
                        templateUrl: 'newsModal.html',
                        controller: 'NewsModalInstanceCtrl',
                        size: size,
                        resolve: {
                            item: function () {
                                return response.data.item;
                            }
                        }
                    });
                });
            }

            var readAnswer = function (response) {
                if (response) {
                    $timeout(function () {
                        $scope.firstNews = response.news;
                        $scope.$apply();
                    });
                }
            }

            // load first items
            news.get($scope.currentPage * $scope.itemsPerPage, $scope.itemsPerPage)
                .then(readAnswer);
        }]);

    app.controller('NewsModalInstanceCtrl', ['$scope', '$uibModalInstance', 'item',
        function ($scope, $uibModalInstance, item) {
            $scope.item = item;
            $scope.selected = {
                item: item
            };

            $scope.ok = function () {
                $uibModalInstance.close($scope.selected.item);
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);

    app.controller("CarouselNewsItemCtrl", ['$scope',
        function ($scope) {
            $scope.myInterval = 5000;
            $scope.noWrapSlides = false;
        }]);

    app.filter('trustAsResourceUrl', ['$sce', function ($sce) {
        return function (val) {
            return $sce.trustAsResourceUrl(val);
        };
    }]);

    app.config(['$locationProvider', '$httpProvider', function ($locationProvider, $httpProvider) {
        // to have the correct relative $location.path()
        $locationProvider.html5Mode({ enabled: true, requireBase: false });
        // to have a "correct" AJAX request when $http.get()
        $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
    }]);
})(angular.module('newsListApp', ['ngSanitize', 'ngAnimate', 'ui.bootstrap']));

/// <reference path="/dist/scripts/!shared/angular/angular-bundle.js"
/// <reference path="/dist/scripts/!shared/jquery/jquery-bundle.js"

(function (app) {
    'use strict';
    var newsPerPage = 10;

    app.run(['$rootScope', function ($rootScope) {
    }]);
    app.service('helper', [function () {
        return {
        };
    }]);
    app.filter('html', ['$sce', function ($sce) {
        return function (val) {
            return $sce.trustAsHtml(val);
        };
    }]);

    app.controller("TopNewsCtrl", ['$scope', '$timeout', '$uibModal', '$log', '$http',
        function ($scope, $timeout, $uibModal, $log, $http) {
            $scope.animationsEnabled = true;

            $scope.itemsPerPage = newsPerPage;
            $scope.firstNews = [];

            $scope.open = function ($event, link, size) {
                $event.preventDefault();
                if (!link) {
                    link = $event.delegateTarget.href;
                }

                $http.get(link).then(function (response) {
                    var modalInstance = $uibModal.open({
                        animation: $scope.animationsEnabled,
                        templateUrl: 'newsModal.html',
                        controller: 'NewsModalInstanceCtrl',
                        size: size,
                        resolve: {
                            item: function () {
                                return response.data.item;
                            }
                        }
                    });
                });
            }

            var readAnswer = function (response) {
                if (response) {
                    $timeout(function () {
                        $scope.firstNews = response.news;
                        $scope.$apply();
                    });
                }
            }
        }]);

    app.controller('NewsModalInstanceCtrl', ['$scope', '$uibModalInstance', 'item',
        function ($scope, $uibModalInstance, item) {
            $scope.item = item;
            $scope.selected = {
                item: item
            };

            $scope.ok = function () {
                $uibModalInstance.close($scope.selected.item);
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);

    app.controller("CarouselNewsItemCtrl", ['$scope',
        function ($scope) {
            $scope.myInterval = 5000;
            $scope.noWrapSlides = false;
        }]);

    app.filter('trustAsResourceUrl', ['$sce', function ($sce) {
        return function (val) {
            return $sce.trustAsResourceUrl(val);
        };
    }]);

    app.config(['$locationProvider', '$httpProvider', function ($locationProvider, $httpProvider) {
        // to have the correct relative $location.path()
        $locationProvider.html5Mode({ enabled: true, requireBase: false });
        // to have a "correct" AJAX request when $http.get()
        $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
    }]);
})(angular.module('topNewsListApp', ['ngSanitize', 'ngAnimate', 'ui.bootstrap']));

angular.bootstrap(document.getElementById('topNewsList'), ['topNewsListApp']);
angular.bootstrap(document.getElementById('newsList'), ['newsListApp']);